import * as React from "react";
import { useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import { experimentalStyled as styled, alpha } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import BathroomIcon from "@material-ui/icons/Bathroom";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import ButtonBase from "@material-ui/core/ButtonBase";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import AccIcon from "../assets/acc.png";
import BcpIcon from "../assets/bcp.png";
import CerminIcon from "../assets/cermin.png";
import KranIcon from "../assets/kran.png";
import KunciIcon from "../assets/kunci.png";
import PintuIcon from "../assets/pintu.png";
import RakIcon from "../assets/rak.png";
import SargotIcon from "../assets/sargot.png";
import ShowerIcon from "../assets/shower.png";
import WastafelIcon from "../assets/wastafel.png";
import Produk from "./produk";
import Berita from "./berita";
import axios from "axios"
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const Search = styled("div")(({ theme }) => ({
  position: "relative",

  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(2, 1, 2, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    borderTop: "1px solid rgba(0, 0, 0, 0.4)",
    boxShadow: "0 2px 3px -2px rgba(0, 0, 0, 0.4)",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
}));
export default function ButtonAppBar(props) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [data,setData] = React.useState([]);
  useEffect(() => {
    if (props?.history?.location?.state?.tab === "produk") {
      setValue(1);
    }
    axios.get('https://cv-mitrabangunan.com/api/catalog/category').then(r => 
    {setData(r.data)})
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        style={{ color: "#000", background: "#fff", zIndex: 99 }}
        position="sticky"
      >
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, fontWeight: "500" }}
            textAlign="center"
          >
            Katalog Mitra Bangunan
          </Typography>
        </Toolbar>

        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab icon={<MenuBookIcon />} label="Katalog" {...a11yProps(0)} />
          <Tab icon={<BathroomIcon />} label="Produk" {...a11yProps(1)} />
          <Tab icon={<LibraryBooksIcon />} label="Berita" {...a11yProps(2)} />
        </Tabs>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            onClick={() => props.history.push("/cariproduk")}
            onFocus={() => props.history.push("/cariproduk")}
            fullWidth
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
          />
        </Search>
      </AppBar>

      <Box sx={{ bgcolor: "background.paper" }}>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Grid container spacing={3}>
              {data && data.map(r => <Grid item xs={6} md={2}>
                <ButtonBase
                  onClick={() => {
                    props.history.push({
                      pathname: "/detailkatalog",
                      state: { katalogId: r.CATEGORY_ID, name:  r.CATEGORY_NAME },
                    });
                  }}
                  style={{ width: "100%", borderRadius: 12 }}
                >
                  <Card style={{ width: "100%", borderRadius: 12 }}>
                    <CardContent>
                      <img src={r.CATEGORY_PICTURE} style={{ width: "100%" }} />
                    </CardContent>
                    <div
                      style={{
                        backgroundColor: "orange",
                        height: 16,
                        borderBottomRightRadius: 12,
                        borderBottomLeftRadius: 12,
                      }}
                    ></div>
                  </Card>
                </ButtonBase>
              </Grid>
            )}
             
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <Produk id={props?.history?.location?.state?.productId ?? null} />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
           <Berita {...props}/>
          </TabPanel>
        </SwipeableViews>
      </Box>
    </Box>
  );
}
