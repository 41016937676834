import { useState, createRef, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";

import Lightbox from "react-image-lightbox";
import axios from "axios"
export default function Produk(props) {
  const [data, setData] = useState([])
  const [index, setIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const refs = data.reduce((acc, value) => {
    acc[value.PRODUCT_ID] = createRef();
    return acc;
  }, {});
  useEffect(()=> {
    axios.get('https://cv-mitrabangunan.com/api/catalog/product').then(r => 
    {setData(r.data)
    
    })
  },[])
  useEffect(() => {
    if (props.id && data) {
      refs[props.id]?.current.scrollIntoView({
        block: "start",
      });
      setIndex(data.findIndex((r) => r.PRODUCT_ID == props.id));
      setOpen(true);
    }
    console.log(props.id)
  }, [props.id, data]);
 
  return (
    <>
      <Grid container spacing={2}>
        {data.map((item, i) => (
          <Grid ref={refs[item.PRODUCT_ID]} item key={item.PRODUCT_ID} xs={4} md={2}>
            <ButtonBase
              onClick={() => {
                setIndex(i);
                setOpen(true);
              }}
              style={{
                width: "100%",
                position: "relative",
                overflow: "hidden",
                margin: " auto",
                paddingBottom: "100%",
              }}
            >
              <img
                style={{
                  position: "absolute",
                  top: "0",
                  margin: "auto",
                  padding: "10px",
                  maxWidth: "90%",
                  maxHeight: "90%",
                }}
                src={item.PRODUCT_PICTURE_THUMB}
                loading="lazy"
              />
            </ButtonBase>
          </Grid>
        ))}
      </Grid>
      {open && (
        <Lightbox
          style={{ zIndex: 999 }}
          mainSrc={data[index]?.PRODUCT_PICTURE}
          nextSrc={data[(index + 1) % data.length]?.PRODUCT_PICTURE}
          prevSrc={data[(index + data.length - 1) % data.length]?.PRODUCT_PICTURE}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setIndex({
              index: (index + data.length - 1) % data.length,
            })
          }
          onMoveNextRequest={() => setIndex((index + 1) % data.length)}
        />
      )}
    </>
  );
}
