import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Katalog from "./components/katalog";
import DetailKatalog from "./components/detailkatalog";
import DetailBerita from "./components/detailberita";
import SearchProduct from "./components/productsearch";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import "react-image-lightbox/style.css";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
function App() {
  return (
    <Router>
      <Switch>
        <Route path="/cariproduk" component={SearchProduct} />
        <Route path="/detailkatalog" component={DetailKatalog} />
        <Route path="/detailberita" component={DetailBerita} />
        <Route path="/" component={Katalog} />
      </Switch>
    </Router>
  );
}

export default App;
