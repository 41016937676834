import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";

import Lightbox from "react-image-lightbox";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import axios from "axios"
export default function DetailKatalog(props) {
  const [index, setIndex] = useState(0);
  const [data, setData] = useState([])
  const [open, setOpen] = useState(false);
  useEffect(()=> {
    let id = props?.history?.location?.state?.katalogId

 axios.get('https://cv-mitrabangunan.com/api/catalog/category/'+id).then(r => 
{setData(r.data)

})

  }, [])
  const namakatalog = () => {
    let nama = props?.history?.location?.state?.name
   
    return nama;
  };
  return (
    <>
      <AppBar
        style={{ color: "#000", background: "#fff", zIndex: 99 }}
        position="sticky"
      >
        <Toolbar>
          <IconButton
            onClick={() => props.history.push("/")}
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, fontWeight: "500" }}
          >
            Katalog {namakatalog()}
          </Typography>
        </Toolbar>
      </AppBar>
      <br />
      <Grid container spacing={2}>
        {data.map((item, i) => (
          <Grid item key={item.CONTENT_ID} xs={4} md={2}>
            <ButtonBase
              onClick={() => {
                setIndex(i);
                setOpen(true);
              }}
              style={{
                width: "100%",
                position: "relative",
                overflow: "hidden",
                margin: " auto",
                paddingBottom: "100%",
              }}
            >
              <img
                style={{
                  position: "absolute",
                  top: "0",
                  margin: "auto",
                  padding: "10px",
                  maxWidth: "90%",
                  maxHeight: "90%",
                }}
                src={item.CONTENT_PICTURE_THUMB}
                loading="lazy"
              />
            </ButtonBase>
          </Grid>
        ))}
        <br/><br/>
      </Grid>
      {open && (
        <Lightbox
          style={{ zIndex: 999 }}
          mainSrc={data[index].CONTENT_PICTURE}
          nextSrc={data[(index + 1) % data.length].CONTENT_PICTURE}
          prevSrc={
            data[(index + data.length - 1) % data.length].CONTENT_PICTURE
          }
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setIndex({
              index: (index + data.length - 1) % data.length,
            })
          }
          onMoveNextRequest={() => setIndex((index + 1) % data.length)}
        />
      )}
    </>
  );
}
