import React, { useState, useEffect } from "react";

import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import SearchIcon from "@material-ui/icons/Search";
import { FixedSizeList } from "react-window";
import Fuse from "fuse.js";
import { experimentalStyled as styled, alpha } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import axios from "axios"
export default function DetailKatalog(props) {
  const [search, setSearch] = React.useState("");
  const [data, setData] = useState([])
  const [dataOri, setDataOri] = useState([])
  useEffect(()=> {
    axios.get('https://cv-mitrabangunan.com/api/catalog/product').then(r => 
    {setDataOri(r.data)
      setData(r.data)
    
    })
  },[])
  useEffect(() => {
    const callSearchService = () => {
      const options = {
        keys: ["PRODUCT_NAME"],
      };

      const fuse = new Fuse(dataOri, options);
      const d = fuse.search(search).map((r) => r.item);

      setData(d);
    };
    if (search != "") {
      callSearchService();
    }
    if (search === "") {
      setData(dataOri)
    }
    console.log(search);
  }, [search]);

  const Search = styled("div")(({ theme }) => ({
    position: "relative",

    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(2, 1, 2, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      borderTop: "1px solid rgba(0, 0, 0, 0.4)",
      boxShadow: "0 2px 3px -2px rgba(0, 0, 0, 0.4)",
      [theme.breakpoints.up("sm")]: {
        width: "100%",
        "&:focus": {
          width: "100%",
        },
      },
    },
  }));
  const Row = ({ index, style }) => (
    <div className={index % 2 ? "ListItemOdd" : "ListItemEven"} style={style}>
      <ListItem
        button
        onClick={() => {
          props.history.push({
            pathname: "/",
            state: { tab: "produk", productId: data[index].PRODUCT_ID },
          });
        }}
      >
        <ListItemAvatar>
          <Avatar
            variant="square"
            alt={data[index].PRODUCT_NAME}
            src={data[index].PRODUCT_PICTURE_THUMB}
          />
        </ListItemAvatar>
        <ListItemText primary={data[index].PRODUCT_NAME} />
      </ListItem>
      <Divider component="li" />
    </div>
  );

  return (
    <>
      <AppBar
        style={{ color: "#000", background: "#fff", zIndex: 99 }}
        position="sticky"
      >
        <Toolbar>
          <IconButton
            onClick={() => props.history.push("/")}
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, fontWeight: "500" }}
          >
            Cari Produk
          </Typography>
        </Toolbar>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            autoFocus
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            fullWidth
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
          />
        </Search>
      </AppBar>

      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        <FixedSizeList
          height={window.innerHeight}
          width="100%"
          itemSize={64}
          itemCount={data.length}
        >
          {Row}
        </FixedSizeList>
      </List>
    </>
  );
}
