import { Card, Grid, Typography } from "@material-ui/core"
import axios from "axios"
import { useEffect, useState } from "react"
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
export default function Berita(props){
    const [data,setData] = useState([])
    useEffect(()=>{

    axios.get('https://cv-mitrabangunan.com/api/latestnews').then(r => 
    {setData(r.data.slice(0,5))
        console.log(r.data)
    })
},[])
    return (<>
    <Grid container>
        {data.map(r =>  <Grid xs={12} px={2} md={4} >
        <Card sx={{minHeight:"320px"}}>
        <CardActionArea sx={{minHeight:"320px"}}
         onClick={() => {
            props.history.push({
              pathname: "/detailberita",
              state: { id: r.blog_id, name:  r.title },
            });
          }}
        >
        <CardMedia
          sx={{ minHeight: 160 }}
          image={r.image}
          title={r.title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
          {r.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
          {r.description}
          </Typography>
        </CardContent>
      </CardActionArea>

        </Card>
        <br/>
        </Grid>)}
       
    </Grid>
    
    </>)
    
}