import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";

import Lightbox from "react-image-lightbox";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import axios from "axios"
import parse, { attributesToProps } from 'html-react-parser';
export default function DetailKatalog(props) {
  const [index, setIndex] = useState(0);
  const [data, setData] = useState([])
  const [open, setOpen] = useState(false);
  useEffect(()=> {
    let id = props?.history?.location?.state?.id

 axios.get('https://cv-mitrabangunan.com/api/detailnews/'+id).then(r => 
{setData(r.data)

})

  }, [])
  const namaberita = () => {
    let nama = props?.history?.location?.state?.name
   
    return nama;
  };
  return (
    <>
      <AppBar
        style={{ color: "#000", background: "#fff", zIndex: 99 }}
        position="sticky"
      >
        <Toolbar>
          <IconButton
            onClick={() => props.history.push("/")}
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, fontWeight: "500" }}
          >
           Detail Berita 
          </Typography>
        </Toolbar>
      </AppBar>
      <br />
      <Grid container spacing={2} p={2}>
        <Grid item xs={12}>
          <Typography variant="h6">
          {namaberita()}
          </Typography>
        
        </Grid>
        <Grid item xs={12}>
        {data.content && parse(`<style>img { maxWidth:"200px"}</style>`+data.content.toString(), {
  replace: domNode => {
    if (domNode.attribs && domNode.name === 'img') {
      const props = attributesToProps(domNode.attribs);
      return <img style={{maxWidth:"100%", height:"auto"}} {...props} />;
    }
  }
})}
        </Grid>
      </Grid>
     
    </>
  );
}
